<template>
    <div>
        <div class="surface-card p-6 border-1 surface-border">
            <data-table></data-table>
        </div>
    </div>
</template>

<script>
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";
    import DataTable from "@/components/data/offer/DataTable";

    export default {
        components: {UpdateViewHeader, DataTable}
    }
</script>
